<template>
    <div> 
        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Ema">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key">                
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                     <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code">
                                <td v-if="column.Status && column.code == 'nhsa_001'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_002'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_003'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_004'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_005'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_006'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nhsa_007'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                 <td v-else-if="column.Status && column.code == 'nhsa_011'" :code="column.code">                                
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>     
                                <td v-else-if="column.Status && column.code == 'nhsa_012'" :code="column.code">
                                    <Readmore :longText="item['indication_en']" />
                                </td>   
                                 <td v-else-if="column.Status && column.code == 'nhsa_030'" :code="column.code">
                                     {{ check_empty(DDMMMYY(item.ma_date)) }}
                                </td> 
                                 <template  v-else-if="column.Status && column.code == 'nhsa_013'" >                             
                                    <td  :key="column.code+'_014'" code="nhsa_014">
                                        <Readmore :longText="item.target_population"/> 
                                    </td>      
                                    <td :key="column.code+'_015'" code="nhsa_015">
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/> 
                                    </td>      
                                    <td :key="column.code+'_016'" code="nhsa_016">
                                        <Readmore :longText="item.access_scheme"/> 
                                    </td>      
                                    <td :key="column.code+'_017'" code="nhsa_017">
                                        <Readmore :longText="item.outcome_level"/> 
                                    </td>      
                                </template>
                                <td v-else-if="column.Status && column.code == 'nhsa_018'" :code="column.code">     
                                    <Readmore :longText="item['rationale_and_comments_html_en']"/>         
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'nhsa_019'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>
                                <template v-else-if="column.Status && column.code == 'nhsa_021'" colspan="4"  :code="column.code">
                                    <td :key="column.code+'_022'" :code="'nhsa_022'">
                                        {{ check_empty(DDMMMYY(item.hta_process_date)) }}
                                    </td>
                                    <td :key="column.code+'_023'" :code="'nhsa_023'">
                                        <span v-html="hta_process_status(item, 'hta.')"></span>
                                    </td>
                                    <td :key="column.code+'_024'" :code="'nhsa_024'">
                                        {{ check_empty(DDMMMYY(item.rembursement_process_date)) }}
                                    </td>
                                    <td :key="column.code+'_025'" :code="'nhsa_025'">
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb.')"></span>
                                    </td>
                                </template>
                                <template v-else-if="column.Status && column.code == 'nhsa_026'" colspan="3"  :code="column.code">
                                    <td :key="column.code+'_027'" :code="'nhsa_027'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_028'" :code="'nhsa_028'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_029'" :code="'nhsa_029'">
                                        -
                                    </td>
                                </template>
                            </template>
                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="nhsa"/>
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, 
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, 
        check_empty, nct_number, treatment_line, datediff, array, color_type_limitation, check_empty_with_locale } from '@/utils'

import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "nhsa",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalEssaisClinique,
        ModalHTAstatus
    },
    data(){
        return {
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        listAgency(){
            return this.$store.getters['result/listAgency'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        hta_process_status,
        hta_process_status_2,
        rembursement_status,
        rembursement_status_2,
        column_status,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_type_limitation,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        overview_regulatory_status,
        modifyPage
    },
}
</script>

<style scoped>

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}

</style>